import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoCristobal from "../../assets/old/img/logos-ase/ico-sancristobal.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="san-cristobal">
        <Helmet>
            <title>San Cristóbal Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia San Cristóbal Seguros: 0810-222-8887."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoCristobal}
                    alt="San Cristóbal Seguros"
                />
                <h4 className="lead post-title text-muted">San Cristóbal Seguros</h4>
                <p className="">Servicio de asistencia: 0810-222-8887.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
            Si tenés Póliza Todo Riesgo, o el siniestro fue entre vehículos asegurados con compañías adheridas al CLEAS, podés simplificar el proceso de reparación de tu vehículo con mínimos trámites, peritaje inmediato y talleres homologados. <a href="https://www.sancristobal.com.ar/solucion-express/" target="_blank" rel="noopener noreferrer">ACCEDÉ A SOLUCIÓN EXPRESS</a>
        </p>

        <p>
            Es importante que dentro de los 3 días hábiles realices la denuncia del siniestro. <br/>
            Documentación mínima obligatoria <br/>
            Para dar curso a la resolución de tu caso necesitamos que presentes:
        </p>

        <ul>
            <li>DNI</li>
            <li>Licencia de conducir vigente</li>
            <li>Cédula verde </li>
            <li>Denuncia policial (para los accidentes donde hubo lesionados y para las denuncias de robo).</li>
        </ul>


        <hr className="pb-4" />



    </SiniestrosLayout>
)
